import "./App.css";
import "./Slider.css";
import React from "react";
import PropTypes from "prop-types";
import { useLayoutEffect, useRef, useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive'
import { FullPage, Slide, controls } from 'react-full-page';
import { db } from "./firebase";
import useMoveScrool from "./Scroll.js";
import { collection, addDoc } from "firebase/firestore";
import logo from "./image/LOGO_sym2.png";
import s1img1 from "./image/phone1.png";
import s1img2 from "./image/phone2.png";
import s1img3 from "./image/phone3.png";
import instacamera from "./image/instacamera.png";
import apple from "./image/Apple.png";
import googleplay from "./image/Googleplay.png";
import s2img1 from "./image/S2img1.png";
import s2img2 from "./image/S2img2.png";
import first from "./img/FirstInsta.png";
import second from "./img/SecondInsta.png";
import third from "./img/ThirdInsta.png";
import fourth from "./img/FourthInsta.png";
import fifth from "./img/FifthInsta.png";
import sixth from "./img/SixthInsta.png";
import seventh from "./img/SeventhInsta.png";
import eighth from "./img/EighthInsta.png";
import ninth from "./img/NinthInsta.png";
import scroll from "./image/scroll.png";
import rectangle from "./image/rectangle.png";
import menu from "./image/menu.png";
import menubutton from "./image/menubutton.png";
import backgroundImage from "./image/Ellipse1.png";
import mobing from "./image/mobing.png";
import isHoverLine from "./image/isHover.png";



function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function useInterval(callback, delay) {
    const savedCallback = useRef();
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

class CustomControls extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    getCurrentSlideIndex: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired,
    scrollToSlide: PropTypes.func.isRequired,
    slidesCount: PropTypes.number.isRequired,
    style: PropTypes.object,
  }

  static defaultProps = {
    className: 'full-page-controls',
    logostyle: {
      width: '15.52vh',
      height: '4.125vh',
      marginLeft: "1.17vh"
    },
  }

  renderSlidesNumbers(currentSlideIndex) {
    const { slidesCount, scrollToSlide, logostyle } = this.props;
    const slidesNumbers = [];
    const buttonProps1 = {
            disabled: currentSlideIndex === 0,
            key: 0,
            onClick: () => scrollToSlide(0),
          };
    const buttonProps2 = {
      disabled: currentSlideIndex === 1,
      key: 1,
      onClick: () => scrollToSlide(1),
    };
    const buttonProps3 = {
      disabled: currentSlideIndex === 2,
      key: 2,
      onClick: () => scrollToSlide(2),
    };
    const buttonProps4 = {
      disabled: currentSlideIndex === 3,
      key: 3,
      onClick: () => scrollToSlide(3),
    };
    const buttonProps5 = {
      disabled: currentSlideIndex === 4,
      key: 4,
      onClick: () => scrollToSlide(4),
    };
    slidesNumbers.push(
    <a href="./" className='headerContent' style={{width: "17.87vh",}}><img src={logo} style={logostyle}></img></a>
    );
    slidesNumbers.push(
      <button className='headerContent' {...buttonProps1} style={{width: "11.523vh", textAlign: "center", marginLeft:"5vh"}}>About</button>
    );
    slidesNumbers.push(
      <button className='headerContent' {...buttonProps2} style={{width: "11.523vh", textAlign: "center"}}>Magazine</button>
    );
    slidesNumbers.push(
      <button className='headerContent' {...buttonProps3} style={{width: "11.523vh", textAlign: "center"}}>App</button>
    );
    slidesNumbers.push(
      <button className='headerContent' {...buttonProps4} style={{width: "11.523vh", textAlign: "center"}}>History</button>
    );
    slidesNumbers.push(
      <button className='headerContent' {...buttonProps5} style={{width: "11.523vh", textAlign: "center"}}>Contact</button>
    );
    slidesNumbers.push(
      <div className='headerContent' style={{width: "51.27vh"}}></div>
    );
    

    /* for (let i = 0; i < slidesCount; i++) {
      
      slidesNumbers.push(<button type="button" {...buttonProps}>{i + 1}</button>);
    } */
    return slidesNumbers;
  }

  render() {
    let footer1style;
    let footer2style;
    let footer3style;

    const {
      getCurrentSlideIndex, slidesCount, className,
    } = this.props;
    const currentSlideIndex = getCurrentSlideIndex();

    if (currentSlideIndex === 0)
    {
      footer1style = {
        visibility: "hidden",
        opacity: "0",
        filter: "invert(14%) sepia(14%) saturate(0%) hue-rotate(239deg) brightness(100%) contrast(91%)"
      };
      footer2style= {
        visibility: "visible",
        opacity: "1",
        filter: "invert(14%) sepia(14%) saturate(0%) hue-rotate(239deg) brightness(100%) contrast(91%)"
      };
      footer3style= {
        visibility: "visible",
        opacity: "1",
        filter: "invert(14%) sepia(14%) saturate(0%) hue-rotate(239deg) brightness(100%) contrast(91%)"
      };
    }
    else if (currentSlideIndex === 4)
    {
      footer1style = {
        visibility: "visible"
      };
      footer2style= {
        visibility: "hidden",
        opacity: "0",
        filter: "invert(14%) sepia(14%) saturate(0%) hue-rotate(239deg) brightness(100%) contrast(91%)"
      };
      footer3style= {
        visibility: "hidden",
        opacity: "0",
        filter: "invert(14%) sepia(14%) saturate(0%) hue-rotate(239deg) brightness(100%) contrast(91%)"
      };
    }
    else {
      
      footer1style = {
        visibility: "hidden",
        opacity: "0",
        filter: "invert(14%) sepia(14%) saturate(0%) hue-rotate(239deg) brightness(100%) contrast(91%)"
      };
      footer2style= {
        visibility: "visible",
        opacity: "1",
        filter: "invert(14%) sepia(14%) saturate(0%) hue-rotate(239deg) brightness(100%) contrast(91%)"
      };
      footer3style= {
        visibility: "visible",
        opacity: "1",
        filter: "invert(14%) sepia(14%) saturate(0%) hue-rotate(239deg) brightness(100%) contrast(91%)"
      };
    }

    return (
      <div className="landscape-header">
        <div className={className}>
          {/*<button
            type="button"
            disabled={currentSlideIndex === 0}
            onClick={this.props.onPrev}
          >
            previous
          </button> */}
          {this.renderSlidesNumbers(currentSlideIndex)}
          {/*<button
            type="button"
            disabled={currentSlideIndex === slidesCount - 1}
            onClick={this.props.onNext}
          >
            next
          </button> */}
        </div>
        <div className='footer1' style={footer1style}>
          <div className="footerbox1">
            <div className="footertext1">Livin'car Company</div>
            <div className="footertext2">리빈카컴퍼니</div>
            <div className="footertext3">All rights Reserved. © 2023</div>
          </div>
          <div className="footerbox2">
            <div className="footertext4">서울시 노원구 초안산로 12, 아정창업팩토리(덕관) 1층 104호</div>
            <div className="footertext4">사업자등록번호: 207-23-91469 (대표: 이성하)</div>
            <div className="footertext4">대표전화: 010-7529-1409</div>
            <div className="footertext4">E-mail: carl@car-ing.space</div>
          </div>
          <div className="footerbox3">
            <div className="footertext5" style={{color:"#4B4C4B",}}><strong>앱 지원 사이트맵</strong></div>
            <a href="https://www.notion.so/livincar/aaa439202dfb46dcbcbb71c4568193b5" className="footertext5">개인정보 처리방침</a>
            <a href="https://www.notion.so/livincar/83ddd0bcc01946798b28abef9056ecd1" className="footertext5">서비스 이용약관</a>
            <a href="https://www.notion.so/livincar/549e16a467054c76be8b4da61303243f" className="footertext5">앱 계정 삭제 요청</a>
          </div>
        </div>
        <div className='footer2'>
          <div className='footer2text' style={footer2style} id="scroll">
            SCROLL DOWN
          </div>
          <img className='footer2img' style={footer3style} src={scroll}/>
        </div>
      </div>
    );
  }
}

export default function App() {
  const [windowWidth, windowHeight] = useWindowSize();
  const items = [first, second, third, fourth, fifth, sixth, seventh, eighth, ninth];
  const itemSize = items.length;
  const sliderPadding = 40;
  const sliderPaddingStyle = `0 ${sliderPadding}px`;
  const transitionTime = 1000;
  const transitionStyle = `all ${transitionTime}ms ease 0s`;
  const 양끝에_추가될_데이터수 = 6;
  const [currentIndex, setCurrentIndex] = useState(양끝에_추가될_데이터수)
  const [slideTransition, setTransition] = useState(transitionStyle);
  const [isSwiping, setIsSwiping] = useState(false);
  const [slideX, setSlideX] = useState(null);
  const [prevSlideX, setPrevSlideX] = useState(false);
  let isResizing = useRef(false);
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const selectList = ["직접 입력","@naver.com", "@gmail.com", "@hanmail.com", "@nate.com", "@kakao.com"];
  const [Selected, setSelected] = useState("직접 입력");
  const [emailplaceholder, setEmailplaceholder] = useState("example@email.com");
  
  // static propTypes = {
  //   className: PropTypes.string,
  //   getCurrentSlideIndex: PropTypes.func.isRequired,
  //   onNext: PropTypes.func.isRequired,
  //   onPrev: PropTypes.func.isRequired,
  //   scrollToSlide: PropTypes.func.isRequired,
  //   slidesCount: PropTypes.number.isRequired,
  //   style: PropTypes.object,
  // }

  const handleSelect = (e) => {
    console.log(e.target.value);
    if (e.target.value !== '직접 입력')
  {
    setEmailplaceholder("example");
  }
  else {
    setEmailplaceholder("example@email.com");
  }
    setSelected(e.target.value);
    console.log(Selected);
    console.log(emailplaceholder);
  };

  

  const [isOpen, setMenu] = useState(false);  // 메뉴의 초기값을 false로 설정

  const toggleMenu = () => {
        setMenu(isOpen => !isOpen); // on,off 개념 boolean
    }

  const toggleClose = () => {
      setMenu(false); // on,off 개념 boolean
  }

  let slides = setSlides();
  function setSlides() {
      let addedFront = [];
      let addedLast = [];
      var index = 0;
      while (index < 양끝에_추가될_데이터수) {
          addedLast.push(items[index % items.length])
          addedFront.unshift(items[items.length - 1 - index % items.length])
          index++;
      }
      return [...addedFront, ...items, ...addedLast];
  }

  useEffect(() => {
      isResizing.current = true;
      setIsSwiping(true);
      setTransition('')
      setTimeout(() => {
          isResizing.current = false;
          if (!isResizing.current)
              setIsSwiping(false)
      }, 1000);
  }, [windowWidth])

  useInterval(() => {
      handleSlide(currentIndex + 1)
  }, !isSwiping && !prevSlideX ? 2000 : null)

  function replaceSlide(index) {
      setTimeout(() => {
          setTransition('');
          setCurrentIndex(index);
      }, transitionTime)
  }

  function handleSlide(index) {
      setCurrentIndex(index);
      if (index - 양끝에_추가될_데이터수 < 0) {
          index += itemSize;
          replaceSlide(index)
      }
      else if (index - 양끝에_추가될_데이터수 >= itemSize) {
          index -= itemSize;
          replaceSlide(index)
      }
      setTransition(transitionStyle);
  }

  function getItemIndex(index) {
      index -= 양끝에_추가될_데이터수;
      if (index < 0) {
          index += itemSize;
      }
      else if (index >= itemSize) {
          index -= itemSize;
      }
      return index;
  }

  function getClientX(event) {
      return event._reactName == "onTouchStart" ? event.touches[0].clientX :
          event._reactName == "onTouchMove" || event._reactName == "onTouchEnd" ? event.changedTouches[0].clientX : event.clientX;
  }

  function handleTouchStart(e) {
      setPrevSlideX(prevSlideX => getClientX(e))
  }

  function handleTouchMove(e) {
      if (prevSlideX) {
          setSlideX(slideX => getClientX(e) - prevSlideX);
      }
  }

  function handleMouseSwipe(e) {
      if (slideX) {
          const currentTouchX = getClientX(e);
          if (prevSlideX > currentTouchX + 100) {
              handleSlide(currentIndex + 1)
          }
          else if (prevSlideX < currentTouchX - 100) {
              handleSlide(currentIndex - 1)
          }
          setSlideX(slideX => null)
      }
      setPrevSlideX(prevSlideX => null)
  }

  const {element, onMoveToElement} = useMoveScrool();

  const [percentage, setPercentage] = useState();
  const [footer1, setFooter1] = useState("footer1Off");
  const [footer2, setFooter2] = useState("footer2On");
  const [S1box2, setS1box2] = useState("S1box2");
  const [S1box3, setS1box3] = useState("S1box3");
  const [S1box4, setS1box4] = useState("S1box4");
  
  const scrollTo = (y) => {
    window.scrollTo({top: (y / 100 * document.documentElement.scrollHeight), behavior:'smooth'});
    toggleClose();
    // window.scrollTo(0, y / 100 * document.documentElement.scrollHeight);
  }

  const getScrollPercentage = () => {
    const scroll = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    const viewport = scrollHeight - clientHeight;
    // const percentage = (scroll / viewport) * 100;
    const percentage = (scroll / scrollHeight) * 100;
  
    return setPercentage(Math.floor(percentage));
  };

  useEffect(() => {
    console.log("percentage",percentage);
    
   
    if (!isPortrait)
    {
      if ((percentage > 25) && (percentage < 44))
      {
        setS1box2("S1box2After");
        setS1box3("S1box3After");
        setS1box4("S1box4After");
      }
      else if ((percentage > 66) && (percentage < 90))
      {
      }
      else{
        setS1box2("S1box2");
        setS1box3("S1box3");
        setS1box4("S1box4");
      }
    }
    else
    {
      if ((percentage > 70))
      {
        setFooter1("footer1On");
        setFooter2("footer2Off");
      }
      else
      {
        setFooter1("footer1Off");
        setFooter2("footer2On");
      }
    }
    
  }, [percentage])

  useEffect(() => {
    window.addEventListener('scroll', getScrollPercentage);
    return () => window.removeEventListener('scroll', getScrollPercentage);
  }, []);
  
  const [instagram, setInstagram] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleChange = ({ target: { value } }) => {
    setInstagram(value);
  }
  const handleSubmit = async (event) => {
    event.preventDefault();    
    try {
      const docRef = await addDoc(collection(db, "email"), {
         instagram, Selected
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    alert(`등록된 EMAIL : ${instagram}${Selected}`);
    setDisabled(true);
    //alert(`이벤트 참여 기간이 종료되었습니다.\n(2022년 12월 30일 금요일 ~ 2023년 1월 6일 금요일)`); 
  };


  return (
    <div className="homepage">
      <FullPage className="main-landscape" scrollMode={isPortrait ? "normal" : "full-page"} controls={CustomControls}>
        <img src={backgroundImage} className="mainBackground"></img>
        <Slide className="Slide2">
          <div className="S2box1">
            <div className="S2box1text1">
              plus your moves&nbsp;
              <img className="S2box1img1" src={s2img1}/>
            </div>
            <div className="S2box1text1">
              <img className="S2box1img2" src={s2img2}/>
              &nbsp;mobility lifestyle
            </div>
            <div className="S2box1text2">
              떠나는 설렘부터 반복되는 출근길까지의 다양한 이동으로 만들어지는 우리의 일상.<br/>
              카잉은 즐거운 이동이 가득한 일상을 만들어 갑니다.
            </div>
          </div>
        </Slide>
        <Slide className="Slide3">
            <div className="S3boxtext1">이동에 일상을 더합니다.</div>
            <div className="S3boxtext2">카잉 인스타그램을 팔로우하고, 당신의 이동에 일상을 <br/>더하는 다양한 모빌리티 소식를 만나보세요.</div>
            <a className='S3boxtext3' href="https://www.instagram.com/car_ing.space/">
                <img src={instacamera}/>
                @car_ing.space
            </a>
          <div className="slider-track"
                style={{
                    transform: `translateX(calc(${(-100 / slides.length) * (0.5 + currentIndex)}% + ${slideX || 0}px))`,
                    // transform: 'translateY(calc(${(-1000 / slides.length) * (0.5 + currentIndex)}%px))',
                    transition: slideTransition,
                }}>
                {
                    slides.map((slide, slideIndex) => {
                        const itemIndex = getItemIndex(slideIndex);
                        let slideName;
                        
                        switch (slideIndex)
                        {
                          case (currentIndex - 4) :
                            slideName = "1";
                            break;
                          case (currentIndex - 3) :
                            slideName = "2";
                            break;
                          case (currentIndex - 2) :
                            slideName = "3";
                            break;
                          case (currentIndex - 1) :
                            slideName = "4";
                            break;
                          case (currentIndex) :
                            slideName = "5";
                            break;
                          case (currentIndex + 1) :
                            slideName = "6";
                            break;
                          case (currentIndex + 2) :
                            slideName = "7";
                            break;
                          case (currentIndex + 3) :
                            slideName = "8";
                            break;
                          case (currentIndex + 4) :
                            slideName = "9";
                            break;
                          default :
                            slideName = "0";
                        }
                        return (
                            <div key={slideIndex} id={`slider-item`} className={`slider-item${slideName}`}
                                onMouseDown={handleTouchStart}
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                onMouseMove={handleTouchMove}
                                onMouseUp={handleMouseSwipe}
                                onTouchEnd={handleMouseSwipe}
                                onMouseLeave={handleMouseSwipe}
                            >
                              <img className={`item${itemIndex}`} src={items[itemIndex]} alt={`banner${itemIndex}`} />  
                            </div>
                        )
                    })
                }
            </div>
        </Slide>
        <Slide className="Slide1">
          <div className="S1box1">
            <div className='S1text1'>일상 속 이동, 일상 속 즐거움, 일상 속 카잉!</div>
            <div className='S1text2'>내 차와 함께 할 수 있는<br/>모든 것을, <span className="highlight">카잉에서.</span></div>
            <div className="S1text3">빠르게 목적지까지 달리기만 했던 드라이빙 대신,<br/>당신의 이동이 즐거운 일상의 일부가 될 수 있도록<br/>지금 카잉 앱에서 다양한 드라이브 코스를 만나보세요.</div>
            <div>
              <a className='S2apple' href="https://apps.apple.com/kr/app/%EC%B9%B4%EC%9E%89-car-ing/id6448982992">
                  <img src={apple}/>
                  App Store
              </a>
              <a className='S2google' href="https://play.google.com/store/apps/details?id=io.ohmyapp.caring.aos">
                  <img src={googleplay}/>
                  Google Play
              </a>
            </div>
          </div>
          <div className={S1box2}>
            <img className="S1img" src={s1img1}/>
            <div className="S1text4">매거진</div>
            <div className="S1text5">당신의 자동차에 일상을 더하는 카잉의<br/>모빌리티 소식을 앱에서 먼저 만나보세요!</div>
          </div>
          <div className={S1box3}>
            <img className="S1img" src={s1img2}/>
            <div className="S1text4">드라이브 코스</div>
            <div className="S1text5">전국 방방 곳곳의 드라이브 코스를<br/>취향에 따라 찾아볼 수 있어요.</div>
          </div>
          <div className={S1box4}>
            <img className="S1img" src={s1img3}/>
            <div className="S1text4">드라이브 후기</div>
            <div className="S1text5">멋진 드라이브를 즐겼다면, 자동차와<br/>함께한 오늘의 기록을 남겨보세요.</div>
          </div>
        </Slide>
        <Slide className="Slide4">
          <div className="S4box1">
            <div className="S4textbox">
                <div className="S4text1">
                  자동차가 일상의<br/>공간이 될 때까지
                </div>
                <div className="S4text2">
                  지루한 일상에서 벗어나 새로운 세상으로 함께 나아가는 사람들.<br/>
                  이동을 통해 일상의 변화를 만들어가는, <strong>우리는 카잉입니다.</strong>
                </div>
            </div>
            <img className="S4img1" src={mobing}/>
          </div>
          <div className="S4box2">
                <div className="S4box3">
                  <img className="S4img2" src={isHoverLine}/>
                  <div className="S4text3">
                    2021. 12
                  </div>
                  <div className="S4text4">
                    ALIGN MSR<br/>
                    대학생 모빌리티 학회 수료
                  </div>
                </div>
                <div className="S4box3">
                  <img className="S4img2" src={isHoverLine}/>
                  <div className="S4text3">
                    2022. 5
                  </div>
                  <div className="S4text4">
                    서울창업카페 우수예비창업팀<br/>
                    지원사업 선정
                  </div>
                </div>
                <div className="S4box3">
                  <img className="S4img2" src={isHoverLine}/>
                  <div className="S4text3">
                    2022. 7
                  </div>
                  <div className="S4text4">
                    학생창업유망팀 300<br/>
                    성장트랙 최종선발
                  </div>
                </div>
                <div className="S4box3">
                  <img className="S4img2" src={isHoverLine}/>
                  <div className="S4text3">
                    2022. 7
                  </div>
                  <div className="S4text4">
                    국민대학교 창업동아리<br/>
                    지원사업(예비창업팀) 선정
                  </div>
                </div>
                <div className="S4box3">
                  <img className="S4img2" src={isHoverLine}/>
                  <div className="S4text3">
                    2023. 1
                  </div>
                  <div className="S4text4">
                    인덕대학교 캠퍼스타운<br/>
                    창업경진대회 입주기업 선정
                  </div>
                </div>
          </div>
          {/* <div className='S4box0'>
            <img src={s4instagram} />
            <div>인스타그램 아이디로 카잉 시작하기</div>
          </div>
          <div className='S4box1'>
            이벤트 참여하고, 카잉 엔트리 키트 받아가세요.
          </div>
          <div className='S4box2'>
            당신에게 자동차는 어떤 공간인가요? 카잉이 새로운 모빌리티 라이프를 만드는 당신께
          </div>
          <div className='S4box3'>
            응원의 마음을 가득 담은 엔트리 키트를 추첨을 통해 스물 다섯분께 드립니다🥰
          </div>
          <form className='S4box4' onSubmit={handleSubmit}>
            <input type="text" value={instagram} onChange={handleChange} placeholder='@인스타그램_아이디를_입력하세요' size="30"/>
            <button type="submit" disabled={disabled}>이벤트 응모하기</button>
          </form> */}
        </Slide>
        {/* <Slide className="Slide4Half">
          <div className='S4Hbox1'>
            <div className='S4box0'>
              <div>이벤트 참여 방법</div>
            </div>
            <div className='S4box5'>
              <div className='S4box5inbox'>
                <img className='title' src={s4title1}/>
                <div className='content'>
                  인스타그램 아이디<br/>
                  등록
                </div>
                <img className='S4coupon' src={s4coupon1}/>
              </div>
              <img className="subtract" src={s4subtract}/>
              <div className='S4box5inbox'>
                <img className='title' src={s4title2}/>
                <div className='content'>
                  @car_ing.space<br/>
                  팔로우
                </div>
                <img className='S4coupon' src={s4coupon2}/>
              </div>
              <img className="subtract" src={s4subtract}/>
              <div className='S4box5inbox'>
                <img className='title' src={s4title3}/>
                <div className='content'>
                  @car_ing.space<br/>
                  태그한 피드 업로드
                </div>
                <img className='S4coupon' src={s4coupon3}/>
              </div>
            </div>
            <ul className='S4Hbox1textbox'>
              <li>
                등록한 인스타그램 아이디로 응모권 한 장이 발급됩니다.
              </li>
              <li>
                각 스텝을 완료할 때마다 응모권을 추가로 받을 수 있습니다.
              </li>
              <li>
                모든 스텝을 완료하면 <strong>총 4장의 응모권</strong>이 발행됩니다. 더 많은 응모권을 모아 당첨 확률을 높여보세요!
              </li>
            </ul>
          </div>
          <div className='S4Hbox2'>
            <div className='S4Hbox2First'>
              <div className='S4box0'>
                <div>이벤트 참여 기간</div>
              </div>
              <div className='S4Hbox2text'>
                2022년 12월 30일&#40;금&#41; ~ 2023년 1월 6일&#40;금&#41;
              </div>
            </div>
            <div className='S4Hbox2Second'>
              <div className='S4box0'>
                <div>당첨자 발표일</div>
              </div>
              <div className='S4Hbox2text'>
                2023년 1월 9일&#40;월&#41; 발표
              </div>
            </div>
          </div>
          <div className='S4Hbox3'>
            *유의사항: 본 이벤트는 홈페이지와 인스타그램을 통해 참여 가능하며, 계정당 1회 참여 가능합니다. 이벤트 내용과 무관한 게시물, 당첨 안내 일에 확인 불가능한 게시물의 경우 이벤트 참여가 인정되지 않습니다. 인스타그램 아이디 등록 시 자동으로 개인정보 수집 및 활용에 동의하게 됩니다. 경품 배송을 위해 추가적인 개인정보 수집을 요청할 수 있으며, 개인정보 제출 거부 시 당첨이 취소될 수 있습니다. 개인정보는 본 이벤트 경품 제공 목적 외의 용도로 사용되지 않으며, 보유 목적 완료 시 파기됩니다. 당첨되신 분께는 2023년 1월 초 개별 안내를 드리며, 안내에 회신을 하지 않은 경우 당첨이 취소될 수 있습니다. 경품은 양도/양수 되지 않으며, 교환 및 환불이 불가합니다. 고객의 부주의로 인한 불이익은 고객에게 있습니다. 본 이벤트는 당사의 사정에 따라 조기종료 될 수 있습니다.
          </div>
          <div className='S4box6'>
            <button className='button1' type="button">참여 방법 안내</button>
            <button className='button2' type="button">유의 사항 보기</button>
          </div>
        </Slide> */}
        <Slide className="Slide5">
            <div className="S5text1">
              카잉에 대해 더 알아보기
            </div>
            <div className="S5text2">
              아래 칸에 이메일을 등록하고 <strong>카잉 앱 소식을 가장 먼저 받아보세요.</strong><br/>
              매주 화요일마다 <strong>앱 업데이트 소식을 담은 뉴스레터</strong>를 보내드립니다.
            </div>
            <form className='S5box' onSubmit={handleSubmit}>
              <div className="S5textbox">
                <input className="S5input" type="text" value={instagram} onChange={handleChange} placeholder={emailplaceholder} size="30"/>
                <select class="emailSelectBox" id="domain-list" onChange={handleSelect}>
                      {selectList.map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <button type="submit" disabled={disabled}>이메일 등록하기</button>
            </form>
          {/* <img className={S3donut} src={s3donut} />
          <img className={S3bag} src={s3bag} />
          <img className={S3blanket} src={s3blanket} />
          <img className={S3tissue} src={s3tissue} />
          <img className={S3sticker} src={s3sticker} />
          <img className={S3tumbler} src={s3tumbler} />
          <div className='S3textbox'>
            <img className='S3img' src={S1img2}/>
            <div className='S3text1'>
              2023 ENTRY KIT
            </div>
            <div className='S3text2'>
              당신의 모빌리티 라이프스타일을 함께할 카잉 엔트리 키트를 소개합니다.
            </div>
          </div> */}
        </Slide>
        {/* <Slide className="Slide5">
          <div className='S5box1'>
            <div className='S5box1inbox1'>
              <div className='S5box1text1' style={{marginBottom:"12.5vh", marginTop:"10vh"}}>2022</div>
              <div className='S5box1text1'>2021</div>
            </div>
            <img src={s5img1}/>
            <div className='S5box1inbox2'>
              <div className='S5box1intextbox1'>
                <div className='S5box1text2'>2022.5</div>
                <div className='S5box1text3'>서울창업카페 우수예비창업팀 지원사업 선정</div>
              </div>
              <div className='S5box1intextbox2'>
                <div className='S5box1text2'>2022.7</div>
                <div className='S5box1text3'>학생창업유망팀 300 성장트랙 선정</div>
              </div>
              <div className='S5box1intextbox3'>
                <div className='S5box1text2'>2022.7</div>
                <div className='S5box1text3'>국민대학교 창업동아리 지원사업 선정</div>
              </div>
              <div className='S5box1intextbox5'>
                <div className='S5box1text2' style={{marginRight: "1.3vh",}}>2022.12<br/><strong>empty</strong></div>
                <div className='S5box1text3'>인덕대학교 캠퍼스타운 I-CULTURE-DU<br/>
                창업경진대회 입주기업 선정</div>
              </div>
              <div className='S5box1intextbox4'>
                <div className='S5box1text2'>2021.12</div>
                <div className='S5box1text3'>ALIGN 대학생모빌리티학회 수료</div>
              </div>
            </div>
          </div>
          <div className='S5box2'>
            <img src={s5img3} className='S5box2img'/>
            <div className='S5box2text1'>
              모빌리티가 당신만의<br/>
              생활공간이 될 때까지
            </div>
            <div className='S5box2text2'>
              모든 이동이 나다운 일상의 연속일 수 있도록<br/>
              새로운 모빌리티 라이프스타일을 만들어갑니다
            </div>
          </div>
        </Slide> */}
        {/* <Slide className="Slide6">
          <div className={S6text}>car+ing space</div>
          <div className={S6textH}>car as a ‘living space’</div>
          <div className='S6text2'>
            리빈카컴퍼니와 함께 모빌리티 라이프스타일을 고민하고 만들어갈 분을 찾습니다.
          </div>
          <div className='S6text3'>
            각종 문의는 아래 이메일 또는 연락처를 통해 남겨주세요. 감사합니다.
          </div>
          <div className='S6box1'>
            <a className='S6text4' href="https://www.instagram.com/car_ing.space/">
              <img src={camera} style={{width: "2.3vh", height: "2.3vh", paddingRight: "2vh", verticalAlign: "middle"}}/>
              @car_ing.space
            </a>
            <div className='S6text5'>
              <img src={message} style={{width: "2.3vh", height: "1.7vh", paddingRight: "2vh", verticalAlign: "middle"}}/>
              carl.plish@gmail.com
            </div>
          </div>
          <img src={rectangle} className={S7rectangle}/>
        </Slide> */}
      </FullPage>
      <portrait className="main-portrait">
          <div className="header">
                <div className="header-wrapper">               
                    <a href="./"><img src={logo} /></a>
                    <div><img src={menu} onClick={()=>toggleMenu()}/></div> 
                </div>
                <div className={isOpen ? "show-menu" : "hide-menu"}>
                  <div className="menulist0"><img src={menubutton} onClick={()=>toggleClose()}/></div>
                  <div onClick={()=>scrollTo(0)} className="menulist1">About</div>
                  <div onClick={()=>scrollTo(18.5)} className="menulist2">Magazine</div>
                  <div className="menulist3" onClick={()=>scrollTo(39)}>App</div>
                  <div className="menulist4" onClick={()=>scrollTo(58)}>History</div>
                  <div className="menulist5" onClick={()=>scrollTo(80)}>Contact</div>
                </div>
          </div>
          <img src={backgroundImage} className="mainBackground"></img>
          <body>
            <div className="section">
              <div className="S2box1">
                <div className="S2box1text1">
                  plus your moves&nbsp;
                  <img className="S2box1img1" src={s2img1}/>
                </div>
                <div className="S2box1text1">
                  <img className="S2box1img2" src={s2img2}/>
                  &nbsp;mobility lifestyle
                </div>
                <div className="S2box1text2">
                  떠나는 설렘부터 반복되는 출근길까지, 다양한 이동으로 만들어지는 우리의 일상. 카잉은 즐거운 이동이 가득한 일상을 만들어 갑니다.
                </div>
              </div>
              <img className="S2img1" src={mobing}/>
            </div>
            <div className="section2" >
              <div className="S3boxtext1">이동에<br/>일상을 더합니다.</div>
              <div className="S3boxtext2">카잉 인스타그램을 팔로우하고, 이동에 일상을<br/>더하는 다양한 모빌리티 소식을 만나보세요.</div>
              <a className='S3boxtext3' href="https://www.instagram.com/car_ing.space/">
                  <img src={instacamera}/>
                  @car_ing.space
              </a>
              <div className="slider-track"
                  style={{
                      transform: `translateX(calc(${(-100 / slides.length) * (0.5 + currentIndex)}% + ${slideX || 0}px))`,
                      // transform: 'translateY(calc(${(-1000 / slides.length) * (0.5 + currentIndex)}%px))',
                      transition: slideTransition,
                  }}>
                  {
                      slides.map((slide, slideIndex) => {
                          const itemIndex = getItemIndex(slideIndex);
                          let slideName;
                          
                          switch (slideIndex)
                          {
                            case (currentIndex - 4) :
                              slideName = "1";
                              break;
                            case (currentIndex - 3) :
                              slideName = "2";
                              break;
                            case (currentIndex - 2) :
                              slideName = "3";
                              break;
                            case (currentIndex - 1) :
                              slideName = "4";
                              break;
                            case (currentIndex) :
                              slideName = "5";
                              break;
                            case (currentIndex + 1) :
                              slideName = "6";
                              break;
                            case (currentIndex + 2) :
                              slideName = "7";
                              break;
                            case (currentIndex + 3) :
                              slideName = "8";
                              break;
                            case (currentIndex + 4) :
                              slideName = "9";
                              break;
                            default :
                              slideName = "0";
                          }
                          return (
                              <div key={slideIndex} id={`slider-item`} className={`slider-item${slideName}`}
                                  onMouseDown={handleTouchStart}
                                  onTouchStart={handleTouchStart}
                                  onTouchMove={handleTouchMove}
                                  onMouseMove={handleTouchMove}
                                  onMouseUp={handleMouseSwipe}
                                  onTouchEnd={handleMouseSwipe}
                                  onMouseLeave={handleMouseSwipe}
                              >
                                <img className={`item${itemIndex}`} src={items[itemIndex]} alt={`banner${itemIndex}`} />  
                              </div>
                          )
                      })
                  }
              </div>
            </div>
            <div className="section3">
              <div className="S1box">
                <div className='S1text1'>일상 속 이동, 일상 속 즐거움, 일상 속 카잉!</div>
                <div className='S1text2'>내 차와 함께 할 수 있는<br/>모든 것을, <span className="highlight">카잉에서.</span></div>
                <div className="S1text3">
                  빠르게 목적지까지 달리기만 했던 드라이빙 대신,<br/>
                  당신의 이동이 즐거운 일상의 일부가 될 수 있도록<br/>
                  지금 카잉 앱에서 다양한 드라이브 코스를 만나보세요.
                  </div>
              </div>
              <div>
                <a className='S3apple' href="https://apps.apple.com/kr/app/%EC%B9%B4%EC%9E%89-car-ing/id6448982992">
                  <img src={apple}/>
                  App Store
                </a>
                <a className='S3google' href="https://play.google.com/store/apps/details?id=io.ohmyapp.caring.aos">
                  <img src={googleplay}/>
                  Google Play
                </a>
              </div>
            </div>
            <div className="section4">
              <div className="S4box1">
                <div className="S4textbox">
                    <div className="S4text1">
                      자동차가 일상의<br/>공간이 될 때까지
                    </div>
                    <div className="S4text2">
                      지루한 일상에서 벗어나 새로운 세상으로 함께 나아가는 사람들.<br/>
                      이동을 통해 일상의 변화를 만들어가는, <strong>우리는 카잉입니다.</strong>
                    </div>
                </div>
              </div>
              <div className="S4box2">
                <div className="S4box3">
                  <img className="S4img2" src={isHoverLine}/>
                  <div className="S4text3">
                    2021. 12
                  </div>
                  <div className="S4text4">
                    ALIGN MSR<br/>
                    대학생 모빌리티 학회 수료
                  </div>
                </div>
                <div className="S4box3">
                  <img className="S4img2" src={isHoverLine}/>
                  <div className="S4text3">
                    2022. 5
                  </div>
                  <div className="S4text4">
                    서울창업카페 우수예비창업팀<br/>
                    지원사업 선정
                  </div>
                </div>
                <div className="S4box3">
                  <img className="S4img2" src={isHoverLine}/>
                  <div className="S4text3">
                    2022. 7
                  </div>
                  <div className="S4text4">
                    학생창업유망팀 300<br/>
                    성장트랙 최종선발
                  </div>
                </div>
                <div className="S4box3">
                  <img className="S4img2" src={isHoverLine}/>
                  <div className="S4text3">
                    2022. 7
                  </div>
                  <div className="S4text4">
                    국민대학교 창업동아리<br/>
                    지원사업(예비창업팀) 선정
                  </div>
                </div>
                <div className="S4box3">
                  <img className="S4img2" src={isHoverLine}/>
                  <div className="S4text3">
                    2023. 1
                  </div>
                  <div className="S4text4">
                    인덕대학교 캠퍼스타운<br/>
                    창업경진대회 입주기업 선정
                  </div>
                </div>
              </div>
            </div>
            <div className="section5">
              <div className="S5text1">
                카잉에 대해 더 알아보기
              </div>
              <div className="S5text2">
                아래 칸에 이메일을 등록하고 <strong>카잉 앱 소식을 가장 먼저 받아보세요.</strong><br/>
                매주 화요일마다 <strong>앱 업데이트 소식을 담은 뉴스레터</strong>를<br/>보내드립니다.
              </div>
              <form className='S5box' onSubmit={handleSubmit}>
                <div className="S5textbox">
                  <input className="S5input" type="text" value={instagram} onChange={handleChange} placeholder={emailplaceholder} size="30"/>
                  <select class="emailSelectBox" id="domain-list" onChange={handleSelect}>
                        {selectList.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <button type="submit" disabled={disabled}>이메일 등록하기</button>
              </form>
            </div>
          </body>
          <footer>
          <div className={footer1}>
              <div className="footertext1">Livin'car Company</div>
              <div className="footertext2">리빈카컴퍼니</div>
              <div className="footertext3">All rights Reserved. © 2023</div>
          </div>
          <div className={footer2}>
            <div className='footer2text' id="scroll">
              SCROLL DOWN
            </div>
            <img className='footer2img' src={scroll}/>
          </div>
          </footer>
        </portrait>
      </div>
         
  );
}